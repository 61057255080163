import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ItemContainer from './ItemContainer'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Chunk extends React.Component {

    constructor(props) {
        super(props);

        let isEditMode = !(
            this.props.object != null &&
            this.props.object.spanishPhrase != null && this.props.object.spanishPhrase.length > 0 &&
            this.props.object.englishPhrase != null && this.props.object.englishPhrase.length > 0
        );

        this.state = {
            isEditMode: isEditMode,
            isDeleted: false,
            spanishPhrase: (this.props.object != null && this.props.object.spanishPhrase != null) ? this.props.object.spanishPhrase : '',
            englishPhrase: (this.props.object != null && this.props.object.englishPhrase != null) ? this.props.object.englishPhrase : ''
        };
    }

    onClickEditButton = () => {
        this.setState({
            isEditMode: true
        });
    }
    onClickOKButton = () => {
        this.props.onSave(this.props.id, {
            spanishPhrase: this.state.spanishPhrase,
            englishPhrase: this.state.englishPhrase
        }).then(response => {
            this.setState({
                isEditMode: false
            });
        });
    }
    onClickDeleteButton = () => {
        this.props.onDelete(this.props.id)
            .then(response => {
                this.setState({
                    isDeleted: true
                });
            });
    }
    onChangedSpanishPhrase = (event) => {
        this.setState({
            spanishPhrase: event.target.value
        });
    }
    onChangedEnglishPhrase = (event) => {
        this.setState({
            englishPhrase: event.target.value
        });
    }

    render() {

        if (this.state.isDeleted === true &&
            this.state.spanishPhrase.length === 0 &&
            this.state.englishPhrase.length === 0) {
                return <></>;
        }
        return <ItemContainer
        >
            <Row>
                <Col
                    style={{ minHeight: '4rem' }}
                >
                    {(this.state.isDeleted === false && this.state.isEditMode === true) ?
                        (<Form.Control
                            type="text"
                            placeholder="Spanish Phrase"
                            onChange={this.onChangedSpanishPhrase}
                            value={this.state.spanishPhrase}
                        />) :
                        (<h2
                            className={'phrase' + (this.state.isDeleted ? ' inactive' : '')}
                        >
                            {this.state.spanishPhrase}
                        </h2>)
                    }
                </Col>
                {this.state.isDeleted === false ?
                    (<Col
                        xs={2}
                        sm={1}
                        style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start' }}
                    >
                        {this.state.isEditMode ?
                            (<FontAwesomeIcon
                                icon="check-square"
                                style={{ cursor: 'pointer', fontSize: '2rem' }}
                                onClick={this.onClickOKButton}
                            />) :
                            (<FontAwesomeIcon
                                icon="pen-square"
                                style={{ cursor: 'pointer', fontSize: '2rem' }}
                                onClick={this.onClickEditButton}
                            />)
                        }
                    </Col>) : ''
                }
            </Row>

            <Row>
                <Col>
                    {(this.state.isDeleted === false && this.state.isEditMode === true) ?
                        (<Form.Control
                            type="text"
                            placeholder="English Phrase"
                            onChange={this.onChangedEnglishPhrase}
                            value={this.state.englishPhrase}
                        />) :
                        (<h5
                            className={'phrase' + (this.state.isDeleted ? ' inactive' : '')}
                        >
                            {this.state.englishPhrase}
                        </h5>)
                    }
                </Col>
                {this.state.isDeleted === false ?
                    (<Col
                        xs={2}
                        sm={1}
                        style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-start' }}
                    >
                        {this.state.isEditMode ?
                            (<FontAwesomeIcon
                                icon="trash"
                                style={{ cursor: 'pointer', fontSize: '2rem' }}
                                onClick={this.onClickDeleteButton}
                            />) :
                            ('')
                        }
                    </Col>) : ''
                }
            </Row>

        </ItemContainer>
    }
}

export default Chunk;
