import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

class Logo extends React.Component {

    render() {
        return (
            <span
                style={{ padding: '0', margin: '0', fontWeight: 'bold', lineHeight: '1', ...this.props.style }}
            >
                <span className={'strongVerb'}>Spanish</span>
                <span>Chunks</span>
            </span>
        );
    }
}

export default Logo;
