import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Logo from './Logo';
import Search from './Search';

class Header extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            useSmallScreenLayout: this.shallUseSmallLayout()
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.reconfigureScreenSize);
    }

    shallUseSmallLayout = () => {
        return (window.innerWidth < 800);
    }

    reconfigureScreenSize = () => {
        let shallUseSmallLayout = this.shallUseSmallLayout();
        if (shallUseSmallLayout !== this.state.useSmallScreenLayout) {

            // console.log('switching to ' + (shallUseSmallLayout ? 'small' : 'big'));
            this.setState({
                useSmallScreenLayout: shallUseSmallLayout
            });
        }
    }

    render() {
        // return one of two different layouts depending on small/big screen sizes

        if (this.state.useSmallScreenLayout === true) {
            // small

            return (
                <Row
                    className='header'
                >
                    <Col
                        xs={6}
                        style={{ marginTop: '.5rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                    >
                        <Logo
                            getBaseConfigs={this.props.getBaseConfigs}
                            style={{ fontSize: '2rem' }}
                        />
                    </Col>
                    <Col
                        xs={6}
                        style={{ marginTop: '.5rem', display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
                    >
                    </Col>

                    <Col
                        xs={12}
                        style={{ paddingTop: '.5rem', paddingBottom: '.5rem' }}
                    >
                        <Search
                            // getDataStore={this.props.getDataStore}
                            // renderObjects={this.props.renderObjects}
                            onSearchMade={this.props.onSearchMade}
                            configs={this.props.configs}
                        />
                    </Col>

                </Row>
            );
        }

        return (
            <Row
                className='header'
            >
                <Col
                    xs={12}
                    sm={2}
                    md={3}
                    className={'logoArea'}
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                >
                    <Logo
                        getBaseConfigs={this.props.getBaseConfigs}
                        style={{ fontSize: '2rem' }}
                    />
                </Col>
                <Col
                    xs={12}
                    sm={7}
                    md={6}
                    style={{ paddingTop: '.5rem', paddingBottom: '.5rem' }}
                >
                    <Search
                        // renderObjects={this.props.renderObjects}
                        // getDataStore={this.props.getDataStore}
                        onSearchMade={this.props.onSearchMade}
                        onNewObjectRequest={this.props.onNewObjectRequest}
                        configs={this.props.configs}
                        initialSearchPhrase={this.props.initialSearchPhrase}
                    // url={this.props.url}
                    />
                </Col>
                <Col
                    xs={12}
                    sm={3}
                    md={3}
                    style={{ paddingTop: '.5rem', paddingBottom: '.5rem', textAlign: 'right' }}
                >

                </Col>
            </Row>
        );
    }
}

export default Header;
