import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class ItemContainer extends React.Component {

    render() {

        return (
            <Col
                xs={12}
                sm={10}
                md={7}
                style={{ marginTop: '.7rem' }}
            >
                <Card
                    className={'itemContainer'}
                >
                    <Card.Body>
                        <Row>
                            <Col>
                                {this.props.children}
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        );
    }
}

export default ItemContainer;
