import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

class Search extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            nextSearch: {}
        };
    }

    shouldComponentUpdate() {
        return false;
    }

    onChange = (event) => {

        try {
            clearTimeout(this.state.nextSearch.timer);
        } catch (ex) {
        }

        let theValue = null;
        try {
            theValue = event.target.value.trim();
        } catch (ex) {
            theValue = '';
        }

        this.setState({
            nextSearch: {
                value: theValue,
                timer: window.setTimeout(this.onRunSearch, 500)
            }
        });
    }

    onRunSearch = () => {
        this.props.onSearchMade(this.state.nextSearch.value);
    }

    render() {

        return (
            <InputGroup
                size="lg"
                style={this.props.style}
            >
                <FormControl
                    placeholder={'Search for anything'}
                    onChange={this.onChange}
                />
                <Button
                    variant="outline-secondary"
                    onClick={this.props.onNewObjectRequest}
                >
                    <FontAwesomeIcon
                        icon="plus"
                    />
                </Button>
            </InputGroup>
        );
    }
}

export default Search;
