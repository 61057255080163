import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import '../App.scss';
import BaseConfigs from '../configs.json';
import Chunk from './Chunk';
import Header from './Header';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faAngleUp,
  faBars,
  faCheck,
  faCheckSquare,
  faCog,
  faEdit,
  faInfoCircle,
  faLanguage,
  faPen,
  faPenSquare,
  faPlus,
  faSearch,
  faSlidersH,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faPenSquare,
  faCheck,
  faPen,
  faCheckSquare,
  faAngleDown,
  faAngleUp,
  faBars,
  faPlus,
  faSearch,
  faSlidersH,
  faCog,
  faEdit,
  faTrash,
  faInfoCircle,
  faLanguage
)

class App extends React.Component {

  constructor(props) {
    super(props);

    // let searchPhrase = null;
    // try {
    //   if (window.location.search.length > 0) {
    //     let parameters = window.location.search.replace(/^\?/, '').trim().split('&')
    //       .filter((paramPair) => {
    //         return paramPair.trim().split('=')[0].trim().toLowerCase() === 'q';
    //       })
    //       .map((paramPair) => {
    //         let elements = paramPair.trim().split('=');
    //         return {
    //           name: elements[0].trim().toLowerCase(),
    //           value: elements[1],
    //         };
    //       });

    //     if (parameters != null && parameters.length > 0) {
    //       searchPhrase = parameters[0].value;
    //     }
    //   }
    // } catch (ex) {
    //   searchPhrase = null;
    // }

    // if (searchPhrase != null && searchPhrase.length > 0) {
    //   this.setState({
    //     initialSearchPhrase: searchPhrase
    //   });
    // }

    this.state = {
      shownChunks: [],
      // initialSearchPhrase: searchPhrase
    };

    // window.addEventListener('popstate', (event) => {
    //   this.setState({
    //     initialSearchPhrase: event.state.searchPhrase
    //   });
    // });
  }

  // componentDidMount() {

  //   let searchPhrase = null;
  //   try {
  //     if (window.location.search.length > 0) {
  //       let parameters = window.location.search.replace(/^\?/, '').trim().split('&')
  //         .filter((paramPair) => {
  //           return paramPair.trim().split('=')[0].trim().toLowerCase() === 'q';
  //         })
  //         .map((paramPair) => {
  //           let elements = paramPair.trim().split('=');
  //           return {
  //             name: elements[0].trim().toLowerCase(),
  //             value: elements[1],
  //           };
  //         });

  //       if (parameters != null && parameters.length > 0) {
  //         searchPhrase = parameters[0].value;
  //       }
  //     }
  //   } catch (ex) {
  //     searchPhrase = null;
  //   }

  //   if (searchPhrase != null && searchPhrase.length > 0) {
  //     this.setState({
  //       initialSearchPhrase: searchPhrase
  //     });
  //   }
  // }

  onSaveChunk = (id, content) => {

    return new Promise((resolve, reject) => {
      fetch(
        `${BaseConfigs.api.endpoint}/chunk/${id}`, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(content)
      })
        .then(res => res.json())
        .then(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  onDeleteChunk = (id) => {

    return new Promise((resolve, reject) => {
      fetch(
        `${BaseConfigs.api.endpoint}/chunk/${id}`, {
        method: 'DELETE',
        cache: 'no-cache'
      })
        .then(
          () => {
            resolve(null);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  onSearchMade = (searchPhrase) => {

    console.log(`onSearchMade: '${searchPhrase}'`);

    searchPhrase = searchPhrase.trim();
    if (searchPhrase.length < 2) {
      this.setState({
        shownChunks: []
      });
      return;
    }

    fetch(
      `${BaseConfigs.api.endpoint}/chunk/search/${encodeURIComponent(searchPhrase)}`, {
      method: 'GET',
      cache: 'no-cache'
    })
      .then(res => res.json())
      .then(
        (response) => {

          let chunkIDs = response.sortedChunkIDs;

          if (chunkIDs.length === 0) {
            this.setState({
              shownChunks: []
            });
            return;
          }

          // window.history.pushState({ searchPhrase: searchPhrase }, null, `?q=${encodeURIComponent(searchPhrase)}`);

          fetch(
            `${BaseConfigs.api.endpoint}/chunk/${chunkIDs.join(',')}`, {
            method: 'GET',
            cache: 'no-cache'
          })
            .then(res => res.json())
            .then(
              (chunkObjects) => {
                let shownChunks = [];
                chunkObjects.forEach(
                  chunkObject => {
                    shownChunks.push(
                      <Chunk
                        key={chunkObject.id}
                        id={chunkObject.id}
                        object={chunkObject}
                        onSave={this.onSaveChunk}
                        onDelete={this.onDeleteChunk}
                      />
                    );
                  });
                this.setState({
                  shownChunks: shownChunks
                });
              },
              (error) => {
              }
            );
        },
        (error) => {
        }
      );

    // this.setState({
    //   searchPhrase: searchPhrase
    // });

  }

  onNewObjectRequest = () => {

    console.log('onNewObjectRequest');

    fetch(
      `${BaseConfigs.api.endpoint}/misc/guid`, {
      method: 'GET',
      cache: 'no-cache'
    })
      .then(res => res.json())
      .then(
        (response) => {
          this.state.shownChunks.unshift((
            <Chunk
              key={response.guid}
              id={response.guid}
              onSave={this.onSaveChunk}
              onDelete={this.onDeleteChunk}
            />
          ));
          this.setState({
            shownChunks: this.state.shownChunks
          });
        },
        (error) => {
        }
      );
  }

  render() {

    return (

      <Container
        key={'theContainer'}
        fluid
      >
        <Header
          configs={BaseConfigs}
          onSearchMade={this.onSearchMade}
          onNewObjectRequest={this.onNewObjectRequest}
        // initialSearchPhrase={this.state.initialSearchPhrase}
        />
        <Row
          className='content'
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
        >
          {this.state.shownChunks}
        </Row>

      </Container>

    );
  }
}

export default App;
